<template>
	<v-container>
		<v-card>
			<template v-slot:title>
			<p class="aboutme-title own-font">Impressum</p>
		</template>
		<v-list lines="one" class="own-font begining-text">
			<v-list-item prepend-icon="mdi-chevron-right" class="datenschutz-list-item">
				<p>
                        Angaben gemäß § 5 TMG:</p>
				<ul>
					<li>
                        Yusuf Kavalci
                      </li>
					<li>
                        Steuerberater
                      </li>
					<li>
                        Haberspirkerstraße 32
                      </li>
					<li>
                        86529 Schrobenhausen
                        </li>
				</ul>
			</v-list-item>
			<v-list-item prepend-icon="mdi-chevron-right" class="datenschutz-list-item">
				<p>
                        Vertreten durch:</p>
				<ul>
					<li>
                        Steuerberater Yusuf Kavalci
                      </li>
				</ul>
			</v-list-item>
			<v-list-item prepend-icon="mdi-chevron-right" class="datenschutz-list-item">
				<p>
                        Kontakt:</p>
				<ul>
								<li>Telefon: <a href="tel:+4982529638812">08252 / 9638812</a></li>
								<li>Mobil: <a href="tel:+4917613697053">0176 / 13697053</a></li>
					<li>
                      E-Mail: info@steuerberatung-kavalci.de
                    </li>
				</ul>
			</v-list-item>
			<v-list-item prepend-icon="mdi-chevron-right" class="datenschutz-list-item">
				<p>
                        Gesetzliche Berufsbezeichnung: </p>
				<ul>
					<li>
                        Steuerberater
                      </li>
				</ul>
			</v-list-item>
			<v-list-item prepend-icon="mdi-chevron-right" class="datenschutz-list-item">
				<p>
                        Die gesetzliche Berufsbezeichnung „Steuerberater“ wurde in der Bundesrepublik Deutschland (Bayern) verliehen.

                    </p>
			</v-list-item>
			<v-list-item prepend-icon="mdi-chevron-right" class="datenschutz-list-item">
				<p>
                        Zuständige Aufsichtsbehörde:</p>
				<ul>
					<li>
                        Steuerberaterkammer München
                      </li>
					<li>
                        Körperschaft des öffentlichen Rechts
                      </li>
					<li>
                        Nederlingerstr. 9
                      </li>
					<li>
                    80638 München
                  </li>
				</ul>
			</v-list-item>
			<v-list-item prepend-icon="mdi-chevron-right" class="datenschutz-list-item">
				<p>
                        Der Berufsstand der Steuerberater unterliegt im Wesentlichen nachfolgenden berufsrechtlichen Regelungen:</p>
				<ul>
					<li>
                        1. Steuerberatungsgesetz (StBerG)
                      </li>
					<li>
                        2. Durchführungsverordnung zum Steuerberatungsgesetz (DVStB)
                      </li>
					<li>
                       3. Berufsordnung der Bundessteuerberaterkammer  (BOStB)
                      </li>
					<li>
                    4. Steuerberatervergütungsverordnung (StBVV)
                  </li>
				</ul>
			</v-list-item>
			<v-list-item prepend-icon="mdi-chevron-right" class="datenschutz-list-item">
				<p>
                    Umsatzsteuer wird gem. § 19 (1) UStG nicht ausgewiesen.
                  </p>
			</v-list-item>
			<v-list-item prepend-icon="mdi-chevron-right" class="datenschutz-list-item">
				<p>
                        Berufshaftpflichtversicherung:</p>
				<ul>
					<li>
                        Die Berufshaftpflichtversicherung besteht bei der R+V Allgemeine Versicherung AG 
                      </li>
					<li>
                        Mittlerer Pfad 24, 70499 Stuttgart
                      </li>
					<li>
                       Versicherungsnummer:  407 84 354863430 S
                      </li>
					<li>
                    Haftungssumme je Versicherungsfall: 250.000 Euro
                  </li>
				</ul>
			</v-list-item>
			<v-list-item prepend-icon="mdi-chevron-right" class="datenschutz-list-item">
				<p>
                        Online-Streitbeilegung:</p>
				<ul>
					<li>
                        Plattform der EU-Kommission zur Online-Streitbeilegung: http://ec.europa.eu/consumers/odr
                      </li>
					<li>
                        Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
                      </li>
				</ul>
			</v-list-item>
		</v-list>
	</v-card>
</v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'Impressum',
});
</script>

<style scoped>
   .own-font {
      font-family: "Roboto", sans-serif;
      font-optical-sizing: auto ;
      font-style: normal;
      word-break: break-word
    }

    .begining-text {
      font-size: 1.2em;
      line-height: 1;
          margin-top: 3vh;
              margin-bottom: 3vh;
              margin-left: 1vw;
              margin-right: 1vw;
    }

    .aboutme-title {
  font-size: 1.4em;
  margin-top: 2vh;
  margin-left: 1vw;
  line-height: 1;
  margin-top: 4vh;
  font-weight: bold;
}

.aboutme-subtitle {
  font-size: 1.3em;
  line-height: 1.2;
  font-weight: 100;
}

.contact-text {
  font-size: 1em;
}

.contact-icons {
  font-size: 3em;
}

.k-logo {
  width: 250px;
}


   @media screen and (max-width: 1300px) {
    .begining-text {
      font-size: 1em;
      line-height: 1;
    }
        .aboutme-title {
      font-size: 1.4em;
    }
    .contact-text {
  font-size: 1em;
}
.contact-icons {
  font-size: 1.5em;
}
.k-logo {
  width: 150px;
}
   }

  @media screen and (max-width: 590px) {
    .begining-text {
      font-size: 0.9em;
      line-height: 1;
    }
        .aboutme-title {
      font-size: 1.1em
    }
    .contact-text {
  font-size: 0.9em;
}
.k-logo {
  width: 100px;
}
   }

   .datenschutz-list-item {
    margin-top: 2vh;
   }

   .datenschutz-list-item p {
    font-weight: bold;
   }
   .no-margin-top p {
    margin-top: 0 !important;
    font-size: 0.9em
   }
</style>