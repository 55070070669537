<template>
	<v-container>
		<v-card>
			<h1 class="aboutme-title own-font">Datenschutz</h1>
      <p class="own-font begining-text">
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
      </p>
      <p class="own-font begining-text">
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
      </p>
      <p class="own-font begining-text">
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
      </p>
	</v-card>
		<v-card>
			<h1 class="aboutme-title own-font">Hinweis zur verantwortlichen Stelle</h1>

      <p class="own-font begining-text">
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
      </p>
     <v-list-item  class="own-font begining-text datenschutz-list-item">
				<ul>
					<li>
                        Yusuf Kavalci
                      </li>
					<li>
                        Haberspirkerstraße 32
                      </li>
								<li>Telefon: <a href="tel:+4982529638812">08252 / 9638812</a></li>
								<li>Mobil: <a href="tel:+4917613697053">0176 / 13697053</a></li>
				</ul>
			</v-list-item>
      <p class="own-font begining-text">
        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet
      </p>

	</v-card>
  <v-card>
		<h1 class="aboutme-title own-font">Speicherdauer</h1>
    
      <p class="own-font begining-text">
        Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
      </p>
	</v-card>
  <v-card>

			<h1 class="aboutme-title own-font">
        Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Webseite
      </h1>


      <p class="own-font begining-text">
        Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.
      </p>

	</v-card>
    <v-card>
			<h1 class="aboutme-title own-font">Widerruf Ihrer Einwilligung zur Datenverarbeitung</h1>

      <p class="own-font begining-text">
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>

	</v-card>
  <v-card>
			<h1 class="aboutme-title own-font">Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</h1>

      <p class="own-font begining-text">
        Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, hast du jederzeit das Recht, aus Gründen, die sich aus deiner besonderen Situation ergeben, gegen die Verarbeitung deiner personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf der eine Verarbeitung beruht, entnimmst du dieser Datenschutzerklärung. Wenn du Widerspruch einlegst, werden wir deine betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die deine Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).
      </p>
      <p class="own-font begining-text">
       Werden deine personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so hast du das Recht, jederzeit Widerspruch gegen die Verarbeitung deiner personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Wenn du widersprichst, werden deine personenbezogenen Daten anschließend nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).
      </p>

	</v-card>
    <v-card>
<h1 class="aboutme-title own-font">Beschwerderecht bei der zuständigen Aufsichtsbehörde</h1>

      <p class="own-font begining-text">
       Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
      </p>

	</v-card>
  <v-card>
			<h1 class="aboutme-title own-font">Recht auf Datenübertragbarkeit</h1>

      <p class="own-font begining-text">
       Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
      </p>

	</v-card>
  <v-card>
			<h1 class="aboutme-title own-font">Auskunft, Löschung und Berichtigung</h1>

      <p class="own-font begining-text">
       Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
      </p>

	</v-card>
  <v-card>
			<h1 class="aboutme-title own-font">Recht auf Einschränkung der Verarbeitung</h1>

      <p class="own-font begining-text">
       Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
      </p>
      <v-list lines="one" class="own-font begining-text">

      <v-list-item prepend-icon="mdi-chevron-right"  class="own-font datenschutz-list-item">
				<ul>
          <li>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
				</ul>
			</v-list-item>
      <v-list-item prepend-icon="mdi-chevron-right"  class="own-font datenschutz-list-item">
				<ul>
          <li>
            Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
          </li>
				</ul>
			</v-list-item>
      <v-list-item prepend-icon="mdi-chevron-right"  class="own-font  datenschutz-list-item">
				<ul>
          <li>
            Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
				</ul>
			</v-list-item>
      <v-list-item prepend-icon="mdi-chevron-right"  class="own-font  datenschutz-list-item">
				<ul>
          <li>
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
				</ul>
			</v-list-item>
      </v-list>
      <p class="own-font begining-text">
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
      </p>

	</v-card> 
    <v-card>
			<h1 class="aboutme-title own-font">Anfrage per E-Mail, Telefon oder Telefax</h1>

      <p class="own-font begining-text">
       Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
      </p>
      <p class="own-font begining-text">
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p class="own-font begining-text">
        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
      </p>

	</v-card>
   </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useWindowSize } from '@vueuse/core'

export default defineComponent({
  name: 'Datenschutz',
data() {
    return {
        windowSize: useWindowSize()
    }
  },
  mounted() {
  },
  computed: {
    isWidthSizeLower(): boolean {
      return this.windowSize.width < 1225;
    }
  }
});
</script>

<style scoped>
   .own-font {
      font-family: "Roboto", sans-serif;
      font-optical-sizing: auto ;
      font-style: normal;
      word-break: break-word
    }

    .begining-text {
      font-size: 1.2em;
      line-height: 1;
          margin-top: 3vh;
              margin-bottom: 3vh;
              margin-left: 1vw;
              margin-right: 1vw;
    }

    .aboutme-title {
  font-size: 1.4em;
  margin-top: 2vh;
  margin-left: 1vw;
  line-height: 1;
  margin-top: 4vh;
  font-weight: bold;
}

.aboutme-subtitle {
  font-size: 1.3em;
  line-height: 1.2;
  font-weight: 100;
}

.contact-text {
  font-size: 1em;
}

.contact-icons {
  font-size: 3em;
}

.k-logo {
  width: 250px;
}


   @media screen and (max-width: 1300px) {
    .begining-text {
      font-size: 1em;
      line-height: 1;
    }
        .aboutme-title {
      font-size: 1.4em;
    }
    .contact-text {
  font-size: 1em;
}
.contact-icons {
  font-size: 1.5em;
}
.k-logo {
  width: 150px;
}
   }

  @media screen and (max-width: 590px) {
    .begining-text {
      font-size: 0.9em;
      line-height: 1;
    }
        .aboutme-title {
      font-size: 1.1em
    }
    .contact-text {
  font-size: 0.9em;
}
.k-logo {
  width: 100px;
}
   }

   .datenschutz-list-item {
    margin-top: 2vh;
   }

   .datenschutz-list-item p {
    font-weight: bold;
   }
   .no-margin-top p {
    margin-top: 0 !important;
    font-size: 0.9em
   }
</style>
